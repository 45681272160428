.banner img {
    width: 100%;
    /* Chiều rộng ảnh bằng chiều rộng parent */
    max-width: 1200px;
    /* Giới hạn tối đa giống container */

    margin: 0 auto;
    /* Căn giữa */
    display: block;
    /* Cần thiết khi dùng margin auto */
}

.nav {
    background-color: rgb(29, 29, 69);
    max-width: 1200px;
    /* Đặt cùng kích thước tối đa */
    margin: 0 auto;
    /* Căn giữa */
}

.nav-link {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;

    color: white;
}

.nav-link:hover {
    text-decoration: underline;
    /* Gạch chân khi hover */
}